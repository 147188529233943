import React from 'react'

import { useLocation } from '@reach/router'

import { PartnerDetailContainer, PartnersContainer } from '../containers'

import { getSelectedId } from 'redesign/utils/queryStringUtils'

import { pageStyles } from './PartnersPage.module.css'

export const PartnersPage = () => {
  const location = useLocation()
  const partnerId = getSelectedId(location.search)
  return (
    <div className={pageStyles}>
      {partnerId !== undefined ? (
        <PartnerDetailContainer partnerId={partnerId} />
      ) : (
        <PartnersContainer />
      )}
    </div>
  )
}
