import type { CommentInputProps } from 'redesign/modules/Role/components/CommentInput/CommentInput'

import type { MappedPartnerRole } from 'redesign/types/MappedPartnerRole'
import type PartnerRole from 'redesign/types/PartnerRole'
import type RoleCandidate from 'redesign/types/RoleCandidate'
import type User from 'redesign/types/User'

const ROLE_CANDIDATE_STATES = {
  SHORTLISTED: {
    value: 'SHORTLISTED',
    name: 'Shortlisted'
  },
  CONTACTED: {
    value: 'CONTACTED',
    name: 'Contacted'
  },
  RESERVED: {
    value: 'RESERVED',
    name: 'Reserved'
  },
  X_PROFILE_REQUESTED: {
    value: 'X_PROFILE_REQUESTED',
    name: 'X-Profile Requested'
  },
  PRESENTED_TO_BIZDEV: {
    value: 'PRESENTED_TO_BIZDEV',
    name: 'Presented to BizDev'
  },
  PITCHED: {
    value: 'PITCHED',
    name: 'Pitched'
  },
  CLIENT_INTERVIEWED: {
    value: 'CLIENT_INTERVIEWED',
    name: 'Client Interviewed'
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    name: 'Accepted'
  },
  BOOKED: {
    value: 'BOOKED',
    name: 'Booked'
  },
  CLOSED: {
    value: 'CLOSED',
    name: 'Closed'
  }
}

export const buildPartnerRoleDescription = (
  role: PartnerRole
): MappedPartnerRole => {
  let skills = ''

  if (role?.roleSkills?.length) {
    skills = role?.roleSkills
      ?.slice(0, 3)
      ?.map(skill => skill.skill.title)
      ?.join(', ')
  }

  const mappedPartnerRole: MappedPartnerRole = {
    value: role?.id,
    label: `${role?.partner?.name} / ${role?.name} (id: ${role?.id})`,
    skills
  }
  return mappedPartnerRole
}

export const mapPartnerRolesToSelectOptions = (data: PartnerRole[]) =>
  data.map(role => buildPartnerRoleDescription(role))

export const mapUsersToMentionList = (users: User[]) => {
  const mentionsList: CommentInputProps['mentionOptions'] = []
  users.forEach(user => {
    mentionsList.push({
      label: user.username,
      value: user.username
    })
  })
  return mentionsList
}

export const isShortlistedRole = ({
  roleId,
  roleCandidates
}: {
  roleId: number
  roleCandidates?: RoleCandidate[]
}) =>
  roleCandidates?.find(
    candidate =>
      candidate?.partnerRole?.id === roleId &&
      Boolean(candidate.shortlistedAt) &&
      !candidate.reservedAt &&
      !candidate.closedAt
  )

export const getRoleCandidateState = (roleCandidate?: RoleCandidate) => {
  if (roleCandidate?.closedAt) {
    return ROLE_CANDIDATE_STATES.CLOSED
  }

  if (roleCandidate?.bookedAt) {
    return ROLE_CANDIDATE_STATES.BOOKED
  }

  if (roleCandidate?.acceptedAt) {
    return ROLE_CANDIDATE_STATES.ACCEPTED
  }

  if (roleCandidate?.clientInterviewedAt) {
    return ROLE_CANDIDATE_STATES.CLIENT_INTERVIEWED
  }

  if (roleCandidate?.pitchedAt) {
    return ROLE_CANDIDATE_STATES.PITCHED
  }

  if (roleCandidate?.presentedToBizdevAt) {
    return ROLE_CANDIDATE_STATES.PRESENTED_TO_BIZDEV
  }

  if (roleCandidate?.xProfileRequestedAt) {
    return ROLE_CANDIDATE_STATES.X_PROFILE_REQUESTED
  }

  if (roleCandidate?.reservedAt) {
    return ROLE_CANDIDATE_STATES.RESERVED
  }

  if (roleCandidate?.contactedAt) {
    return ROLE_CANDIDATE_STATES.CONTACTED
  }

  if (roleCandidate?.shortlistedAt) {
    return ROLE_CANDIDATE_STATES.SHORTLISTED
  }

  return null
}
