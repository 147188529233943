import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo, useState } from 'react'

import type { ItemType } from 'antd/lib/menu/hooks/useItems'

import { Input } from 'components//Input'
import { Avatar } from 'components/Avatar'
import { Dropdown } from 'components/Dropdown'
import type { MenuProps } from 'components/Menu'
import { Menu } from 'components/Menu'

import { USER_GROUP } from 'redesign/modules/User/constants'

import { useUserList } from 'redesign/hooks/useUserList'

import { groupByKey } from 'utils/helpers'

import type User from 'redesign/types/User'

import type { AssigneeDropdownProps } from './AssigneeDropdown.types'
import { AssigneeLabel } from './AssigneeLabel'

import { assigneeAvatarStyles } from './Assignees.module.css'

export const AssigneeDropdown = ({
  assignees,
  authData,
  handleAssigneeChange,
}: AssigneeDropdownProps) => {
  const [search, setSearch] = useState<string>()
  const { data: usersList } = useUserList()

  const assigneesById: {[key: number]: User} = useMemo(
    () => assignees?.reduce(
      (acc, assignee) => ({ ...acc, [assignee.id]: assignee }), {}) || {}
    , [assignees])

  const isAssignedUser = useCallback((id: number) => (assigneesById[id] !== undefined), [assigneesById])

  const sortAlphabeticallyByEmail = (userList: User[] | undefined) => userList?.sort((user1: User, user2: User) => user1.email.localeCompare(user2.email))

  const assigneesMenuItems: MenuProps['items'] = useMemo(() => {
    if (assignees) {
      return [{
        label: 'Assigned',
        type: 'group',
        children: sortAlphabeticallyByEmail(assignees)
          ?.filter(user => {
            const isNotMe = user.id !== authData.id
            const fitsSearch = search && user.email.search(search) >= 0
            return search ? fitsSearch : isNotMe
          })
          ?.map(assignee => ({
            label: <AssigneeLabel user={assignee} isAssigned={isAssignedUser(assignee.id)} />,
            key: assignee.id,
          })),
      }]
    }

    return []
  }, [assignees, authData.id, isAssignedUser, search])

  const usersMenuItems: MenuProps['items'] = useMemo(() => {
    const userGroups = groupByKey(sortAlphabeticallyByEmail(usersList), 'group')

    if (!userGroups) {
      return []
    }

    // Grouped menu items
    const validGroups: MenuProps['items'] = Object.keys(userGroups)
      .filter(key => key !== 'null')
      .map(key => ({
        label: (USER_GROUP as never)[key],
        type: 'group',
        children: userGroups[key]
          ?.filter((user: User) => {
            const isNotMe = user.id !== authData.id
            const isNotAssigned = !isAssignedUser(user.id)
            const fitsSearch = search && user.email.search(search) >= 0
            return search ? fitsSearch : isNotMe && isNotAssigned
          })
          ?.map((assignee: User) => ({
            label: <AssigneeLabel user={assignee} isAssigned={isAssignedUser(assignee.id)} />,
            key: assignee.id,
          })),
      }))

    // Ungrouped menu items
    const nullGroup: MenuProps['items'] = Object.keys(userGroups)
      .filter(key => key === 'null')
      .map(key => ({
        label: 'Ungrouped',
        type: 'group',
        children: userGroups[key]
          ?.filter((user: User) => {
            const isNotMe = user.id !== authData.id
            const isNotAssigned = !isAssignedUser(user.id)
            const fitsSearch = search && user.email.search(search) >= 0
            return search ? fitsSearch : isNotMe && isNotAssigned
          })
          ?.map((assignee: User) => ({
            label: <AssigneeLabel user={assignee} isAssigned={isAssignedUser(assignee.id)} />,
            key: assignee.id,
          }))
      }))

    const dividerType: ItemType = {
      type: 'divider',
      key: 'divider',
    }

    return [
      ...validGroups,
      dividerType,
      ...nullGroup,
    ]
  }, [authData.id, isAssignedUser, search, usersList])

  const menuItems = useMemo(
    () => [
      {
        label: (
          <div onClick={e => e.stopPropagation()}>
            <Input
              allowClear
              placeholder="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.currentTarget.value)}
              value={search}
            />
          </div>
        ),
        key: 'search',
      },
      ...(authData ? [
        {
          label: 'Me',
          type: 'group',
          children: search ? [] : [{
            label: <AssigneeLabel user={authData} isAssigned={isAssignedUser(authData.id || 0)} />,
            key: authData.id
          }],
        } as ItemType
      ] : []),
      ...(assigneesMenuItems ?? []),
      ...usersMenuItems,
    ],
    [assigneesMenuItems, authData, isAssignedUser, search, usersMenuItems]
  )

  const handleMenuClick: MenuProps['onClick'] = event => {
    const userId = Number(event.key)
    const isAssigned = isAssignedUser(userId)
    handleAssigneeChange({ userId, isAssigned })
  }

  const menu = (
    <Menu onClick={handleMenuClick} items={menuItems} />
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      overlayStyle={{
        maxHeight: `${8 * 32 + 42}px`, // 8 items of 32px + the search field of 42px
        minWidth: '250px',
        overflowY: 'auto'
      }}
    >
      <Avatar className={assigneeAvatarStyles}>+</Avatar>
    </Dropdown>
  )
}
