import React, { useState } from 'react'

import { useLocation } from '@reach/router'

import { Overview } from './components/Overview'
import { OverviewForm } from './components/OverviewForm'

import { Result } from 'components/Result'

import { Skeleton } from 'redesign/components/Skeleton'

import usePartner from '../../hooks/usePartner/usePartner'

import { usePartnerRolesConfig } from 'redesign/hooks/usePartnerRolesConfig'

import { getNotFoundPageErrorMessage } from 'redesign/utils/helpers'

type Props = {
  partnerId: number
}

export const PartnerOverviewContainer = ({ partnerId }: Props) => {
  const location = useLocation()
  const {
    data: partnerData,
    updatePartner,
    updatePartnerIsLoading,
    error
  } = usePartner({ partnerId })
  const { data: partnerRolesConfig, isLoading } = usePartnerRolesConfig()

  const [editMode, setEditMode] = useState(false)

  if (isLoading) {
    return <Skeleton active />
  }
  const errorSubtitleMessage = getNotFoundPageErrorMessage({
    status: error?.response?.status,
    type: 'partner',
    id: partnerId,
    params: location.search
  })

  if (error || !partnerData) {
    return (
      <Result
        status={error?.response?.status}
        title={error?.response?.statusText}
        subTitle={errorSubtitleMessage}
      />
    )
  }

  const closeForm = () => setEditMode(false)

  return editMode ? (
    <OverviewForm
      values={partnerData}
      rolesTimezones={partnerRolesConfig.rolesTimezones}
      closeForm={closeForm}
      updatePartner={updatePartner}
      updatePartnerIsLoading={updatePartnerIsLoading}
    />
  ) : (
    <Overview partnerData={partnerData} onEdit={() => setEditMode(true)} />
  )
}
