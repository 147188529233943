import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { Contact } from '../Contact/Contact'
import { NoContacts } from '../NoContacts/NoContacts'

import { Button } from 'components/Button'

import { App } from 'redesign/components/App'

import type { PartnerContact } from 'redesign/types/PartnerContact'

import { cardGroupStyles, headerStyles, informationStyles } from './Contacts.module.css'

type Props = {
  partnerContactsData: PartnerContact[]
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setContact: React.Dispatch<React.SetStateAction<PartnerContact | undefined>>
  onClickDelete: (id: number) => void
}

export const Contacts = (props: Props) => {
  const { modal: { confirm } } = App.useApp()

  const { partnerContactsData, setIsModalOpen, setContact, onClickDelete } = props
  const onDelete = (contact: PartnerContact) => {
    confirm({
      title: 'Are you sure you want to delete this contact?',
      content: contact.name,
      icon: <ExclamationCircleOutlined />,
      width: '450px',
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        onClickDelete(contact.id)
      }
    })
  }

  const onCLickCreate = () => {
    setContact(undefined)
    setIsModalOpen(true)
  }

  const onClickEdit = (contact: PartnerContact) => {
    setContact(contact)
    setIsModalOpen(true)
  }

  return (
    <div>
      <div className={headerStyles}>
        <div className={informationStyles}>Contacts Information</div>
        <Button type="primary" onClick={onCLickCreate}>Create</Button>
      </div>
      <div className={cardGroupStyles}>
        {partnerContactsData?.length ? partnerContactsData.map(contact =>
          <Contact
            key={contact.id}
            contact={contact}
            onDelete={() => onDelete(contact)}
            onClickEdit={() => onClickEdit(contact)}
          />
        ) : (
          <NoContacts />
        )}
      </div>
    </div>
  )
}
