import React from 'react'

import { CheckOutlined } from '@ant-design/icons'

import CavalryAvatar from 'components/CavalryAvatar'

import type User from 'redesign/types/User'

import { labelWrapperStyles, avatarWrapperStyles, assigneeNameStyles, checkedStyles } from './AssigneeLabel.module.css'

type AssigneeLabelProps = {
  user: User
  isAssigned?: boolean
}

export const AssigneeLabel = ({ user, isAssigned }: AssigneeLabelProps) => (
  <div className={labelWrapperStyles}>
    <span className={avatarWrapperStyles}>
      <CavalryAvatar {...user} />
      <span className={assigneeNameStyles}>
        {user.email}
      </span>
    </span>
    {isAssigned && (
      <CheckOutlined className={checkedStyles}/>
    )}
  </div>
)
