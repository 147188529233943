import { useMemo } from 'react'

import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'

import { message } from 'components/Message'

import {
  listPartnerRoles,
  addAssigneesService,
  removeAssigneesService
} from 'services/partnerRoles'

import { mapPartnerRolesToSelectOptions } from 'redesign/utils/partnerRoleUtils'

import type { MutationOptions } from 'redesign/types/Mutation'

import type {
  IUsePartnerRolesReturn,
  PartnerRoleAssigneesInput
} from './usePartnerRoles.types'

interface Params {
  active?: boolean
  populate?: string[]
  partner?: number
  _limit?: number
  job?: string
  // eslint-disable-next-line camelcase
  id_nin?: number[]
  '_where[_or][0][name_contains]'?: string | null
  '_where[_or][1][partner.name_contains]'?: string | null
}

type UsePartnerRolesProps = {
  enabled?: boolean
  params?: Params
}

const usePartnerRoles = ({
  enabled,
  params
}: UsePartnerRolesProps = {}): IUsePartnerRolesReturn => {
  const queryClient = useQueryClient()

  const { data: partnerRolesData, isLoading } = useQuery({
    queryKey: ['partnerRoles', params],
    enabled: enabled,
    queryFn: () =>
      listPartnerRoles({
        params: {
          active: true,
          _limit: 999,
          populate: [
            'partner',
            'recruiter',
            'bizdevUser',
            'roleSkills',
            'assignees',
            'roleSkills.skill',
            'team'
          ],
          ...params
        }
      })
  })

  const data = useMemo(
    () => partnerRolesData?.data || [],
    [partnerRolesData?.data]
  )
  const mappedPartnerRoles = useMemo(
    () =>
      partnerRolesData?.data
        ? mapPartnerRolesToSelectOptions(partnerRolesData?.data)
        : [],
    [partnerRolesData?.data]
  )

  const addAssigneesMutation = useMutation({
    mutationFn: addAssigneesService,
    onSuccess: () => {
      message.success('Partner Role assignee successfully added')
      queryClient.invalidateQueries({
        queryKey: ['partnerRoles']
      })
    },
    onError: () => {
      message.error('Adding Partner Role assignee failed')
    }
  })

  const addRoleAssigneesMutation = (
    values: PartnerRoleAssigneesInput,
    options?: MutationOptions
  ) => {
    addAssigneesMutation.mutateAsync(values, options)
  }

  const removeAssigneesMutation = useMutation({
    mutationFn: removeAssigneesService,
    onSuccess: () => {
      message.success('Partner Role assignee successfully removed')
      queryClient.invalidateQueries({
        queryKey: ['partnerRoles']
      })
    },
    onError: () => {
      message.error('Removing Partner Role assignee failed')
    }
  })

  const removeRoleAssigneesMutation = (
    values: PartnerRoleAssigneesInput,
    options?: MutationOptions
  ) => {
    removeAssigneesMutation.mutateAsync(values, options)
  }

  return {
    data,
    mappedPartnerRoles,
    isLoading,
    addRoleAssigneesMutation,
    removeRoleAssigneesMutation
  }
}
export default usePartnerRoles
