import React from 'react'

import { AvatarGroup } from 'components/Avatar'
import CavalryAvatar from 'components/CavalryAvatar'

import { AssigneeDropdown } from './AssigneeDropdown'
import type { AssigneesProps } from './Assignees.types'

import { assigneesTableStyles, assigneesWrapperStyles } from './Assignees.module.css'

export const Assignees = ({
  assignees,
  authData,
  shouldUseTableStyles,
  handleAssigneeChange,
}: AssigneesProps) => (
  <div className={shouldUseTableStyles ? assigneesTableStyles : assigneesWrapperStyles} onClick={e => e.stopPropagation()}>
    <AvatarGroup>
      {assignees?.sort((a, b) => a.email.localeCompare(b.email)).map(assignee => (
        <CavalryAvatar key={assignee.id} {...assignee} />
      ))}
      {authData && handleAssigneeChange && (
        <AssigneeDropdown assignees={assignees} authData={authData} handleAssigneeChange={handleAssigneeChange} />
      )}
    </AvatarGroup>
  </div>
)
