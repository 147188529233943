import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { listUsers } from 'services/users'

import type User from 'redesign/types/User'

import type { IUseUserListReturn } from './useUserList.types'

const ROLE_IDS = {
  bizdev: 5,
  manager: 4,
  cavalry: 3
}

const useUserList = (): IUseUserListReturn => {
  const { data: usersList } = useQuery<AxiosResponse<Array<User>>, AxiosError>({
    queryKey: ['usersList'],
    queryFn: () =>
      listUsers({
        params: {
          role_in: [ROLE_IDS.cavalry, ROLE_IDS.manager, ROLE_IDS.bizdev],
          _limit: 1000
        }
      })
  })
  const data = useMemo(() => usersList?.data, [usersList])
  const usersById = useMemo(() => data?.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}), [data])
  return {
    data,
    usersById,
  }
}
export default useUserList
