import React from 'react'

import Tooltip from '../Tooltip/Tooltip'

import { Tag } from 'components/Tag'

import { buildTooltipElement, renderDate } from 'utils/helpers'
import { formatPartnerRoleName } from 'utils/role-candidates-helpers'

import type { RecentActivity } from 'redesign/types/RecentActivity'
import type RoleCandidate from 'redesign/types/RoleCandidate'

import {
  contactedStyles,
  xProfileRequestedStyles,
  presentedToBizDevStyles
} from './RecentActivities.module.css'

type Props = {
  roleCandidate: RoleCandidate
}

export const RecentActivities = (props: Props) => {
  const { roleCandidate } = props
  const {
    id,
    shortlistedAt,
    reservedAt,
    pitchedAt,
    bookedAt,
    closedAt,
    contactedAt,
    xProfileRequestedAt,
    presentedToBizdevAt,
    transferredTo,
    rejectedFit,
    rejectedBy,
    rejectedAt,
    screenedFit,
    screenedBy,
    screenedAt,
    qualifiedFit,
    qualifiedBy,
    qualifiedAt,
    clientInterviewedAt,
    acceptedAt,
    profile_screenings: profileScreenings,
    profile_tech_evals: profileTechEvals
  } = roleCandidate
  const { partnerRole: newPartnerRole } = transferredTo ?? {}

  const roleCandidateActivities: RecentActivity[] = []

  let activities: React.ReactElement[] = []

  // Rejected: has screening or tech eval with roleFit = false
  const screenRejected = profileScreenings?.find(
    screening => screening.roleFit === false
  )
  const techEvalRejected = profileTechEvals?.find(
    techEval => techEval.roleFit === false
  )

  const rejected = screenRejected ?? techEvalRejected
  const isRejected = rejected ?? rejectedFit
  const rejectedType =
    rejected === screenRejected ? 'Screening' : 'Technical Evaluation'

  if (isRejected) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-rejected`,
      name: 'Rejected',
      color: 'red',
      title: `Rejected by ${
        rejected?.author?.username ?? rejectedBy
      } through a ${rejectedType} on (${renderDate(
        rejected?.updated_at ?? rejectedAt
      )}).`,
      date: rejected?.updated_at ?? rejectedAt,
      testId: 'rejected-at-activity'
    })
  }

  // Screened: has screening with roleFit = true
  const screened = profileScreenings?.find(screening => screening.roleFit)
  const isScreened = screened ?? screenedFit
  if (!isRejected && isScreened) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-screened`,
      name: 'Screened',
      color: 'gold',
      title: `Screened by ${
        screened?.author?.username ?? screenedBy
      } on (${renderDate(screened?.updated_at ?? screenedAt)}).`,
      date: screened?.updated_at ?? screenedAt,
      testId: 'screened-at-activity'
    })
  }

  // Qualified: has techEval with roleFit = true
  const qualified = profileTechEvals?.find(techEval => techEval.roleFit)
  const isQualified = qualified ?? qualifiedFit
  if (!isRejected && isQualified) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-qualified`,
      name: 'Qualified',
      color: 'purple',
      title: `Qualified by ${
        qualified?.author.username ?? qualifiedBy
      } on (${renderDate(qualified?.updated_at ?? qualifiedAt)}).`,
      date: qualified?.updated_at ?? qualifiedAt,
      testId: 'qualified-at-activity'
    })
  }

  if (shortlistedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-shortlisted`,
      name: 'Shortlisted',
      color: 'cyan',
      date: roleCandidate.shortlistedAt,
      testId: 'shortlisted-at-activity'
    })
  }

  if (contactedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-contacted`,
      name: 'Contacted',
      date: roleCandidate.contactedAt,
      testId: 'contacted-at-activity',
      className: contactedStyles
    })
  }

  if (reservedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-reserved`,
      name: 'Reserved',
      color: 'green',
      date: roleCandidate.reservedAt,
      testId: 'reserved-at-activity'
    })
  }

  if (xProfileRequestedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-x-profile-requested`,
      name: 'X-Profile',
      title: `X-Profile Requested by ${
        roleCandidate.xProfileRequestedBy?.username
      } on ${renderDate(roleCandidate.xProfileRequestedAt)}`,
      color: 'geekblue',
      date: roleCandidate.xProfileRequestedAt,
      testId: 'x-profile-requested-at-activity',
      className: xProfileRequestedStyles
    })
  }

  if (presentedToBizdevAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-presented`,
      name: 'BizDev',
      title: `Presented to BizDev by ${
        roleCandidate.presentedToBizdevBy?.username
      } on ${renderDate(roleCandidate.presentedToBizdevAt)}`,
      color: 'geekblue',
      date: roleCandidate.presentedToBizdevAt,
      testId: 'presented-at-activity',
      className: presentedToBizDevStyles
    })
  }

  if (pitchedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-pitched`,
      name: 'Pitched',
      color: 'magenta',
      date: roleCandidate.pitchedAt,
      testId: 'pitched-at-activity'
    })
  }

  if (clientInterviewedAt) {
    const {
      partnerRole: {
        id: roleId,
        name: roleName,
        partner: {
          name: partnerName,
        } = {}
      } = {},
      clientInterviewedBy,
    } = roleCandidate

    const role = formatPartnerRoleName({ preposition: 'for', partnerName, roleId, roleName })
    const author = clientInterviewedBy?.username ? `by ${clientInterviewedBy?.username}` : ''
    const date = renderDate(clientInterviewedAt)

    roleCandidateActivities.push({
      key: `tooltip-${id}-client-interviewed`,
      name: 'Client Interviewed',
      title: (<>Client Interviewed {role} {author} on {date}</>),
      color: 'blue',
      date: roleCandidate.clientInterviewedAt,
      testId: 'client-interviewed-at-activity'
    })
  }

  if (acceptedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-accepted`,
      name: 'Accepted',
      color: 'green',
      date: roleCandidate.acceptedAt,
      testId: 'accepted-at-activity'
    })
  }

  if (bookedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-booked`,
      name: 'Booked',
      color: 'blue',
      date: roleCandidate.bookedAt,
      testId: 'booked-at-activity'
    })
  }

  if (newPartnerRole?.name && closedAt) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-transferred`,
      name: 'Transferred',
      color: 'red',
      date: roleCandidate.closedAt,
      testId: 'transferred-at-activity'
    })
  }

  if (closedAt && !newPartnerRole?.name) {
    roleCandidateActivities.push({
      key: `tooltip-${id}-closed`,
      name: 'Closed',
      color: 'red',
      date: roleCandidate.closedAt,
      testId: 'closed-at-activity'
    })
  }
  activities = roleCandidateActivities
    .sort((a, b) => new Date(a.date ?? 0)?.valueOf() - new Date(b.date ?? 0)?.valueOf())
    .map(({ key, name, color, title, testId, className }) => (
      <Tooltip
        key={key}
        title={title ?? buildTooltipElement(name, roleCandidate)}
      >
        <Tag color={color} data-testid={testId} className={className}>
          {name}
        </Tag>
      </Tooltip>
    ))

  return <div data-testid="role-candidate-activities">{activities}</div>
}
