// extracted by mini-css-extract-plugin
export var alligatorGreen = "#3E4F13";
export var blackMesa = "#1F2611";
export var brightNavyBlue = "#177DDC";
export var c64ntscBlue = "#5A7AFF";
export var colors = "'theme/colors.module.css'";
export var contactedStyles = "RecentActivities-module--contactedStyles--e7567";
export var dayBreakBlue = "#111D2C";
export var parkWalkGreen = "#8BBB11";
export var poseidonBlue = "#15395B";
export var presentedToBizDevStyles = "RecentActivities-module--presentedToBizDevStyles--4c365";
export var sambucusBlue = "#131629";
export var ultramarineViolet = "#1C2755";
export var xProfileRequestedStyles = "RecentActivities-module--xProfileRequestedStyles--30617";